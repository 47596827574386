#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #000;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

.h-50 {
  height: 50px !important;
}

/* Header  */

.navbar {
  height: 85px;
  position: fixed;
  width: 100%;
  z-index: 11;
}

.navbar .nav-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
}

.navbar .nav-link:hover {
  color: #dae0ff;
  transition: all .2s ease-in-out;
}

.nav-ic {
  background: linear-gradient(94.94deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 100%), #FFFFFF;
  border-radius: 40px;
  display: inline-flex;
  font-size: 24px;
  text-align: center;
  color: rgb(218, 224, 255);
  height: 40px;
  width: 40px;
  padding: 1px;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.nav-ic:hover {
  background: linear-gradient(180deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 100%), #FFFFFF;
  color: #000817;
  transition: all .1s ease-in-out;
}

.nav-ic .icon {
  background: #0E0504;
  display: inline-flex !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  height: 100%;
  transition: all .3s ease-in-out;

}

.nav-ic:hover .icon {
  background: transparent;
  transition: all .3s ease-in-out;

}


/* .nav-ic {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  padding: 6px 12px;
  color: #fff;
  border-radius: 6px;
  transition: all .1s ease-in-out;
}

.nav-ic .iconify{
  font-size: 24px;
}

.nav-ic:hover {
  background: linear-gradient(180deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 100%), #FFFFFF;
  color: #000817;
  transition: all .1s ease-in-out;
}


.dropdown:hover .dropdown-menu {
  display: block;
  top: 100%;
  left: 0;
  background: black;
} */

.navbar-collapse.collapsing {
  transition: none !important;
}

/* Banner */

.banner {
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  background-image: url(../public/assets/bg1.png);
  background-position: top right;
  background-size: 100%;
  background-repeat: no-repeat;
}

.banner h1 {
  font-weight: 700;
  font-size: 80px;
  line-height: 112px;
  background: linear-gradient(93.66deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 85.65%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.banner p {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: rgba(255, 255, 255, .7);
}

.primary-btn {
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000817;
  background: linear-gradient(94.94deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 100%), #FFFFFF;
  border-radius: 30px;
  padding: 14px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.primary-btn:hover {
  background: linear-gradient(180deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 100%), #FFFFFF;
  transition: all .3s ease-in-out;
}

.primary-btn-outline {
  background: linear-gradient(94.94deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 100%), #FFFFFF;
  border-radius: 40px;
  display: inline-flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: white;
  height: 50px;
  max-height: 50px;
  width: 170px;
  padding: 1px;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.primary-btn-outline:hover {
  background: linear-gradient(180deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 100%), #FFFFFF;
  color: #000817;
  transition: all .1s ease-in-out;
}

.primary-btn-outline span {
  background: #0E0504;
  display: inline-flex !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  height: 100%;
  transition: all .3s ease-in-out;

}

.primary-btn-outline:hover span {
  background: transparent !important;
  transition: all .3s ease-in-out;

}

.left-aside,
.right-aside {
  width: 172px;
  margin-top: 60px;
  position: relative;
}

.left-aside p,
.right-aside p {
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  color: #808080;
  margin-bottom: 0px;
}

.left-aside::before {
  content: "";
  position: absolute;
  right: -10px;
  height: 70%;
  background: #808080;
  width: 2px;

}

.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.z-1 {
  z-index: 1;
}

/* @media (max-width: 1224px) {
  .navbar-nav {
    flex-direction: row;
  }
  .navbar {
    height: auto;
  }

  .navbar .nav-link {
    font-size: 18px;
  }
  
} */

@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

}


@media(max-width: 767px) {

  .navbar {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .banner {
    position: relative;
    z-index: 0;
    align-items: flex-start;
    height: auto;
    background-size: cover;
    background-position: center;
    padding-bottom: 100px;
  }

  .banner h1 {
    margin-bottom: 20px;
    font-size: 42px;
    line-height: 60px;
    padding-top: 120px;
  }

  .banner p {
    font-size: 20px;
    line-height: 30px;
  }

  .banner::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000000a6;
    position: absolute;
    z-index: 0;
    overflow-y: auto;
  }

  .banner p {
    margin-bottom: 35px;
  }

  .left-aside,
  .right-aside {
    margin-top: 45px;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 111;
    display: block !important;
  }

  .navbar-collapse.show {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 11;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 32px;
  }

}

/* roadmap  */

.roadmap{
  padding: 100px 0;
  margin-bottom: 100px;
}

.roadmap-card{
  background: hsl(0deg 0% 100% / 7%);
  backdrop-filter: blur(35px);
  border-radius: 20px;
  /* border: 1px solid #A6B5FF; */
  padding: 50px 25px;
}
/* .map-bg{
  background: url(../public/assets/mapbg.png);
  height: 300px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto;

} */
.map-dot{
background: linear-gradient(90.66deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 85.65%), #FFFFFF;
height: 32px;
width: 32px;
border-radius: 40px;
margin: 0 auto 25px auto;
position: relative;
z-index: 111;
}

.map-dot::after{
  content: "";
  position: absolute;
  left: 32px;
  background: url(../public/assets/mapbg.png);
  height: 2px;
  top: 16px;
  z-index: 0;
  width: 250px;
}
.last::after{
  content: none;
}
.roadmap h5{
font-weight: 700;
font-size: 28px;
line-height: 32px;
text-align: center;
margin-bottom: 15px;
background: linear-gradient(93.66deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 85.65%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.roadmap p{
  font-weight: 400;
font-size: 20px;
line-height: 26px;
color: #ffffffc3;
text-align: center;
}

.h-40{
  height: 40px;
}

/* feature cards  */

.feature {
  display: flex;
  align-items: center;
  background-image: url(../public/assets/bg2.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 200px 0;
  margin-bottom: 100px;
}

.feature-card {
  padding: 20px;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(35px);
  border-radius: 20px;
  border: 1px solid #A6B5FF;
  height: 100%;
}

.feature-img {
  width: 52px;
  height: 52px;
  border-radius: 40px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.feature-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.feature-card h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: white;
  text-align: center;
  margin-bottom: 15px;
}

.feature-card p {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, .7);
}


.feature h2, .roadmap h2 {
  font-weight: 700;
font-size: 71px;
line-height: 90px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(93.66deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 85.65%), #FFFFFF;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  margin-bottom: 60px;
}


/* how-it-work  */

.how-it-work h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, .7);
  margin-bottom: 20px;
}

.how-it-work h2 {
  font-weight: 700;
  font-size: 72px;
  line-height: 107px;
  background: linear-gradient(93.66deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 85.65%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 20px;
}

.how-it-work p {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: rgba(255, 255, 255, .7);
  margin-bottom: 20px;

}

/* collection  */

.collection {
  display: flex;
  align-items: center;
  background-image: url(../public/assets/bg3.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 200px 0;
}

.collection h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  background: linear-gradient(93.66deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 85.65%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 30px;
}

/* blog  */

.blog-card {
  padding: 20px;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(35px);
  border-radius: 20px;
  border: 1px solid #A6B5FF;
  margin-bottom: 25px;
}

.blog .small {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 25px;
  color: rgba(255, 255, 255, .7);
}

.blog h5 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #FFFFFF;
  flex: 1;
  margin-bottom: 0;
}

.blog h4 {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, .7);
  margin-bottom: 20px;
}

.blog h3 {
  font-weight: 700;
  font-size: 88px;
  line-height: 107px;
  background: linear-gradient(93.66deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 85.65%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}



/* footer  */

footer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}
.footer-link:hover{
  color: #dae0ff;
}

@media (max-width: 767px){
  .feature{
    padding: 150px 0;
    background-size: cover;
  }
  .feature-card{
    padding: 20px;
  }
  .feature img{
    height: 100px;
  }
  .feature h2, .roadmap h2 {
    font-size: 54px;
    line-height: 60px;
  }
  .how-it-work h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .how-it-work h2 {
    font-size: 52px;
    line-height: 60px;
  }
  .how-it-work p {
    font-size: 22px;
    line-height: 28px;
  }
  .collection{
    padding: 70px 0;
    background-size: contain;
  }
  .collection h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .blog .small {
    font-size: 16px;
    line-height: 18px;
  }
  .blog h5 {
    font-size: 24px;
    line-height: 34px;
  }
  .blog h3 {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 40px;
  }
  .footer-logo{
    text-align: center;
    margin-bottom: 30px;
  }

.map-dot{
  margin: 0 auto 25px 0;
}
  .map-dot::after{
    content: "";
    position: absolute;
    left: 16px;
    background: url(../public/assets/mapbg-v.png);
    height: 180px;
    top: 16px;
    z-index: 0;
    width: 2px;
  }
  .last::after{
    content: none !important;
  }
 
  .roadmap p,.roadmap h5{
  text-align: left;
  padding-left: 40px;
  }
}